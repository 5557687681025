import { Children } from "react"
import "./TitledCards1.css"
import SectionTitle from "../text/SectionTitle"

// Used on home page slide 2

export default function TitledCards1(props) {
    return (
    <div className="TitledCards1 slide">
        <SectionTitle>{props.title}</SectionTitle>
        <div className="container">
            {props.children ? Children.map(props.children, child => (
                <div className="child">{child}</div>
            )) : null}
        </div>
    </div>
    )
}