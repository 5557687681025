import SectionTitle from "../text/SectionTitle"
import './DocumentEmbed.css'

export default function DocumentEmbed(props) {
    return (
        <div className="DocumentEmbed slide">
            <SectionTitle>{props.title}</SectionTitle>
            <iframe src={props.src} title={props.title} frameborder="0"></iframe>
            <a href={props.src} download>File download</a>
        </div>
    )
}