import CardPalette1 from "../slides/CardPalette1";
import CardPalette2 from "../slides/CardPalette2";
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

import Article1 from '../images/article1.jpg';
import Article2 from '../images/article2.jpg';
import Article3 from '../images/article3.jpg';
import Article4 from '../images/article4.jpg';
import Article5 from '../images/article5.jpg';
import Article6 from '../images/article6.jpg';
import Article7 from '../images/article7.jpg';
import Article8 from '../images/article8.jpg';
import Article9 from '../images/article9.jpg';
import ImageCard1 from "../cards/ImageCard1";

export default function News() {
    useEffect(()=>observe(), [])
    return (
    <>
    <CardPalette1
        title="Trending Stories"
        card1={<ImageCard1 href='https://www.renewableenergyworld.com/podcasts/what-ferc-did-and-didnt-do-to-jumpstart-transmission/' backgroundImage={Article2}>
            <h2>What FERC did, and didn’t, do to jumpstart transmission</h2>
        </ImageCard1>}
        card2={<ImageCard1 href='https://www.renewableenergyworld.com/wind-power/offshore/first-monopile-foundation-installed-at-dominions-coastal-virginia-offshore-wind/' backgroundImage={Article3}>
            <h4>First monopile foundation installed at Dominion’s Coastal Virginia Offshore Wind</h4>
        </ImageCard1>}
        card3={<ImageCard1 href='https://www.renewableenergyworld.com/news/from-saving-pucks-to-saving-the-planet-rangers-legend-mike-richter-is-flourishing-as-an-unlikely-environmentalist/' backgroundImage={Article1}>
            <h4>From saving pucks to saving the planet: Rangers legend Mike Richter is flourishing as an “unlikely environmentalist”</h4>
        </ImageCard1>}
        card4={<ImageCard1 href='https://www.renewableenergyworld.com/solar/rooftop/spokane-deploys-washingtons-first-solar-permit-software-solarapp/' backgroundImage={Article4}>
            <h4>Spokane deploys Washington’s first solar permit software, SolarAPP+</h4>
        </ImageCard1>}
        card5={<ImageCard1 href='https://www.usnews.com/news/us/articles/2024-05-21/republican-led-us-states-challenge-white-house-environmental-review-reforms' backgroundImage={Article5}>
            <h4>Republican-Led US States Challenge White House Environmental Review Reforms</h4>
        </ImageCard1>}
    />
    <CardPalette2
        title="Archives"
        card1={<ImageCard1 href='https://www.usnews.com/news/us/articles/2024-05-15/us-southwest-and-texas-at-risk-of-power-shortfalls-this-summer-regulator-says' backgroundImage={Article6}>
            <h4>US Southwest and Texas at Risk of Power Shortfalls This Summer, Regulator Says</h4>
        </ImageCard1>}
        card2={<ImageCard1 href='https://www.cnbc.com/2024/05/21/heres-how-to-buy-renewable-energy-from-your-electric-utility.html' backgroundImage={Article7}>
            <h4>Here’s how to buy renewable energy from your electric utility</h4>
        </ImageCard1>}
        card3={<ImageCard1 href='https://www.cnbc.com/2024/05/15/half-million-school-buses-in-us-could-be-an-ev-powerhouse-feeding-grid.html' backgroundImage={Article8}>
            <h4>A half-million school buses across US could become EV battery powerhouse feeding energy back onto the grid</h4>
        </ImageCard1>}
        card4={<ImageCard1 href='https://www.cnbc.com/2024/05/14/china-india-still-rely-heavily-on-coal-climate-targets-remain-difficult.html' backgroundImage={Article9}>
            <h4>China and India still rely heavily on coal, climate targets remain ‘very difficult’ to achieve</h4>
        </ImageCard1>}
    />
    </>
    )
}