import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom'

import { useEffect } from "react";
import { useLocation } from "react-router";

import Layout from './pages/Layout';
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import About from './pages/About';
import News from './pages/News';
import Documentation from './pages/Documentation';
import Benefits from './pages/Benefits';
import Sources from './pages/Sources';
import Initiatives from './pages/Initiatives';

import Solar from './pages/srcSolar';
import Geo from './pages/srcGeo';
import Wind from './pages/srcWind';
import Nuclear from './pages/srcNuclear'
import Hydropower from './pages/srcHydro';
import Fossil from './pages/srcFossil'

export default function App() {
    return (
      <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="news" element={<News />} />
            <Route path="documentation" element={<Documentation />} />
            <Route path="benefits" element={<Benefits />} />
            <Route path="initiatives" element={<Initiatives />} />
            <Route path="sources" element={<Sources />} />
            <Route path="sources/solar" element={<Solar />} />
            <Route path="sources/wind" element={<Wind />} />
            <Route path="sources/geothermal" element={<Geo />} />
            <Route path="sources/nuclear" element={<Nuclear />} />
            <Route path="sources/hydropower" element={<Hydropower />} />
            <Route path="sources/fossil-fuels" element={<Fossil />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
      </BrowserRouter>
    );
  }

  const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
    return <>{props.children}</>
  };
  