import HorizontalCard1 from "../cards/HorizontalCard1";
import VerticalCard1 from "../cards/VerticalCard1";
import FullImage1 from "../slides/FullImage1";
// import FullImageInfo from "../slides/FullImageInfo";
import TitledCardList from "../slides/TitledCardList";
import TitledCards1 from "../slides/TitledCards1";
import ImageCard1 from "../cards/ImageCard1";
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

import InstallationImage1 from '../images/r-architecture-TRCJ-87Yoh0-unsplash.jpg'
import PracticesImage1 from '../images/francesco-gallarotti-ruQHpukrN7c-unsplash.jpg'
import EnergiesImage1 from '../images/mischa-frank-6M9OhcblUHM-unsplash.jpg'
import WindImgSmall from '../images/vasilios-muselimis-C9NtZAEP-RI-unsplash-small.jpg'
import GeoImgSmall from '../images/khamkeo-vilaysing-y7-cY2ZTwHc-unsplash-small.jpg'
import SolarImgSmall from '../images/andreas-gucklhorn-Ilpf2eUPpUE-unsplash-small.jpg'
import NuclearImgSmall from '../images/lukas-lehotsky-vMQFh9rAkeU-unsplash-small.jpg'
import HydroImgSmall from '../images/american-public-power-association-FUeb2npsblQ-unsplash-small.jpg'
import FossilImgSmall from '../images/chris-leboutillier-TUJud0AWAPI-unsplash-small.jpg'
import ImpactsImage from '../images/sandie-clarke-q13Zq1Jufks-unsplash.jpg'
import SourceCard from "../cards/SourceCard";
import GreenVideo from "../videos/compressed.mp4"
import SolarPanelInstall from '../images/bill-mead-wmaP3Tl80ww-unsplash.jpg'
import GeoInstall from '../images/sean-d-YO5q_7BN7kQ-unsplash.jpg'
import WindowInstall from '../images/nathan-fertig-U8zsjmKA840-unsplash.jpg'
import RainwaterImage from '../images/mike-kotsch-HNx4QLRgy2k-unsplash.jpg'

export default function Home() {
    useEffect(()=>observe(), [])
    return (
    <>
    <FullImage1
        videoSrc={GreenVideo}
        btnLink="benefits"
        btnText="Find How"
        title="Saving with the Green Homes Project"
    />
    <TitledCards1 title='Getting Started'>
        <VerticalCard1 src={InstallationImage1}>
            <h3>Installation</h3>
            <p>Through installing greener appliances from LED light bulbs to solar panels can help reduce your impact on the environment.</p>
        </VerticalCard1>
        <VerticalCard1 src={PracticesImage1}>
            <h3>Practices</h3>
            <p>Small actions from turning off lights to managing heating can save energy and money.</p>
        </VerticalCard1>
        <VerticalCard1 src={EnergiesImage1}>
            <h3>Energy</h3>
            <p>Learn about different sources of energy and their impact on the world.</p>
        </VerticalCard1>
    </TitledCards1>
    <SourceCard src={ImpactsImage} to="/benefits" desc="By switching to green energy systems in your home, you can save money and contribute to bettering our planet.">Your Impacts</SourceCard>
    <TitledCardList title='Practice'>
        <HorizontalCard1
        src={SolarPanelInstall}
        title="Solar Panel Installations"
        text="Installing solar panels on your home is a powerful way to harness renewable energy and significantly cut down on electricity costs. Solar panels convert sunlight into electricity, which can power your home and even allow you to sell excess energy back to the grid. This not only reduces dependence on fossil fuels but also provides energy security and predictability in your energy expenses. Modern solar panels are increasingly efficient and durable, offering long-term savings and minimal maintenance. Additionally, many regions offer tax incentives and rebates for solar installations, making the transition to solar energy even more attractive financially." />
        <HorizontalCard1
        src={GeoInstall}
        title="Geothermal Installations"
        text="Geothermal installations provide an innovative and efficient method for heating and cooling your home. This system utilizes the stable temperatures found just below the Earth's surface to regulate indoor temperatures through a series of pipes and heat exchangers. By tapping into this natural and sustainable energy source, geothermal systems can reduce energy consumption by up to 70% compared to traditional heating and cooling methods. Although the initial installation cost can be high, the long-term energy savings and potential incentives can offset these expenses. Moreover, geothermal systems are known for their reliability and longevity, requiring less maintenance and providing consistent performance year-round." />
        <HorizontalCard1
        src={WindowInstall}
        title="Efficient Appliances and Windows"
        text="Upgrading to energy-efficient appliances and windows is a straightforward yet impactful way to enhance your home's energy efficiency. Modern energy efficient appliances and heating systems are designed to use significantly less electricity and water while maintaining or even improving performance. Similarly, installing energy-efficient windows helps to minimize heat loss in the winter and reduce heat gain in the summer, thus lowering heating and cooling costs. These improvements not only contribute to lower utility bills but also enhance the overall comfort and environmental footprint of your home. Investing in these upgrades can lead to substantial long-term savings and a reduced carbon footprint." />
        <HorizontalCard1
        src={RainwaterImage}
        title="Rainwater Collection and Composting"
        text="Implementing rainwater collection and composting systems can greatly improve the sustainability and efficiency of your home. Rainwater collection systems capture and store rainwater, which can be used for irrigation, flushing toilets, and other non-potable uses, reducing your reliance on municipal water supplies. Composting, on the other hand, transforms organic waste into nutrient-rich soil, reducing landfill waste and providing a valuable resource for gardening. Both practices contribute to water conservation, waste reduction, and soil health, promoting a more sustainable lifestyle. Together, they make your home more self-sufficient and environmentally friendly, while also providing cost savings on water and waste management." />
    </TitledCardList>
    <TitledCards1 title='Energy Sources'>
        <ImageCard1 href='/sources/solar' backgroundImage={SolarImgSmall}>
            <h3>Solar</h3>
        </ImageCard1>
        <ImageCard1 href='/sources/nuclear' backgroundImage={NuclearImgSmall}>
            <h3>Nuclear</h3>
        </ImageCard1>
        <ImageCard1 href='/sources/geothermal' backgroundImage={GeoImgSmall}>
            <h3>Geothermal</h3>
        </ImageCard1>
        <ImageCard1 href='/sources/hydropower' backgroundImage={HydroImgSmall}>
            <h3>Hydropower</h3>
        </ImageCard1>
        <ImageCard1 href='/sources/wind' backgroundImage={WindImgSmall}>
            <h3>Wind</h3>
        </ImageCard1>
        <ImageCard1 href='/sources/fossil-fuels' backgroundImage={FossilImgSmall}>
            <h3>Fossil Fuels</h3>
        </ImageCard1>
    </TitledCards1>
    </>
    )
}