import TitleSlide from "../slides/TitleSlide"
import ArticleSection from "../text/ArticleSection"
import SectionTitle from "../text/SectionTitle"
import TextSection from "../text/TextSection"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

export default function Geo(props) {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Using Geothermal Energy</TitleSlide>
    <TextSection>
        <SectionTitle>Helping the Environment</SectionTitle>
        <ArticleSection>
            &emsp;Geothermal energy systems use the stable temperatures found underground to heat and cool homes efficiently. For homeowners, geothermal heat pumps offer a sustainable, low-maintenance solution that can drastically cut heating and cooling costs. These systems are environmentally friendly, as they reduce greenhouse gas emissions and rely on a renewable energy source.
            <br />
            &emsp;Geothermal energy systems offer a highly efficient way to heat and cool your home using the earth's stable underground temperatures. By installing a geothermal heat pump, homeowners can enjoy consistent and comfortable indoor climates year-round while drastically cutting energy costs. These systems are incredibly eco-friendly, as they rely on renewable energy and produce minimal greenhouse gas emissions. Additionally, geothermal installations require less maintenance and have a longer lifespan compared to conventional HVAC systems, providing both economic and environmental benefits.
        </ArticleSection>
    </TextSection>
    </>
}