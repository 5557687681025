import "./DropdownButton.css";
import "./NavButton.css";

import { Link } from "react-router-dom";

function DropdownOption(props) {
    return <div className="DropdownOption subtitle"  onClick={props.onClick}>
        <Link to={props.to}>{props.name}</Link>
    </div>
}

function DropdownButton(props) {
    return <div className="DropdownButton NavButton subtitle">
        <Link to={props.to}>
            <span onClick={props.onClick}>{props.name} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/></svg></span>
            <div className="dropdown-option-container">
                {props.children}
            </div>
        </Link>
    </div>
}

export {
    DropdownOption,
    DropdownButton,
}