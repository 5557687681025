import "./InitiativesSection.css"

import Tag from "../buttons/Tag"

export default function InitiativesSection(props) {    
    return <>
    <div className="slide InititiativesSection">
        <div className="tags">
            {props.tags.map(tag =>
                <Tag key={tag} select={props.select}>{tag}</Tag>
            )} 
        </div>
        <div className="initiatives">
            {props.children}
        </div>
    </div>
    </>
}