import TitleSlide from "../slides/TitleSlide";
import SectionTitle from "../text/SectionTitle";
import AnnotatedImage from "../slides/AnnotatedImage";
import ArticleSection from "../text/ArticleSection";
import SubsectionTitle from "../text/SubsectionTitle";
import ContactUs from "../slides/ContactUs";
import TextSection from "../text/TextSection";
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

import PeopleImg from "../images/campaign-creators-gMsnXqILjp4-unsplash.jpg"

export default function About() {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Learn About our Team</TitleSlide>
    <TextSection>
        <SectionTitle>Our Team</SectionTitle>
        <AnnotatedImage src={PeopleImg}></AnnotatedImage>
        <SubsectionTitle>Hear Our Stories</SubsectionTitle>
        <ArticleSection>
            &emsp;We are the Green Homes Project. We are a clean energy non-profit and we want to help you help the planet. Since our founding in 2007 by former Secretary of Energy Mary Rogers, we have worked tirelessly to bring you the latest innovations in clean energy as well as making it as affordable as possible. Over our 17 years of service, we have brought clean energy and energy-efficient systems to over 120,000 homes and have reduced carbon emissions by 480,000 tons annually through education and advocacy. We hope that through us you find something that suits both you and the environment to improve everyone's life.
        </ArticleSection>
    </TextSection>
    <TextSection>
        <SectionTitle>Our Mission</SectionTitle>
        <ArticleSection>
            &emsp;Our mission is to make every household in America carbon neutral to improve both the planet and your quality of life. With our outreach and advocacy, we aim to educate everyone on the benefits and feasibility of clean energy and efficient systems. We want to show the world that a brighter, cleaner future is possible.
        </ArticleSection>
    </TextSection>
    <ContactUs />
    </>
}