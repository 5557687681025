// Used for home landing full image

import LinkButton1 from "../buttons/LinkButton1";
import "./FullImage1.css";


export default function FullImage1(props) {
    return (
    <div className="FullImage1 slide wide">
        <video autoplay muted loop autoPlay>
            <source src={props.videoSrc} type="video/mp4" />
        </video>
        <div className="container">
            <h2 className="observable">{props.title}</h2>
            <LinkButton1 to={props.btnLink}>{props.btnText}</LinkButton1>
        </div>
    </div>
    )
}