import TitleSlide from "../slides/TitleSlide"
import ArticleSection from "../text/ArticleSection"
import SectionTitle from "../text/SectionTitle"
import TextSection from "../text/TextSection"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

export default function Hydropower(props) {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Using Hydropower</TitleSlide>
    <TextSection>
        <SectionTitle>Helping the Environment</SectionTitle>
        <ArticleSection>
            &emsp;Hydropower generates electricity by harnessing the energy of flowing water. As a large-scale clean energy source, it provides a reliable and consistent power supply with minimal emissions. Supporting hydropower initiatives contributes to a sustainable energy grid, reducing the need for fossil fuels and helping to combat climate change.
            <br />
            &emsp;Hydropower is a clean and reliable energy source that harnesses the power of flowing water to generate electricity. While it is typically utilized on a larger scale, supporting hydropower projects can significantly contribute to a sustainable energy future. Hydropower plants produce minimal emissions and provide a consistent energy supply, making them an essential component of a low-carbon energy grid. By advocating for hydropower, homeowners can support the expansion of renewable energy infrastructure and the reduction of fossil fuel dependency.
        </ArticleSection>
    </TextSection>
    </>
}