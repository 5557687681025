import './ContactUs.css'
import SectionTitle from '../text/SectionTitle'

export default function ContactUs(props) {
    return <div className='ContactUs slide'>
        <SectionTitle>Contact Us</SectionTitle>
        <form action='/' >
            <iframe className='maps-embed' title='Google Maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2771.160420818182!2d-81.43834572963834!3d28.425602131236126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77e0cc65a19a7%3A0x4d324d6cffd94d76!2sRosen%20Shingle%20Creek!5e0!3m2!1sen!2sus!4v1716179564503!5m2!1sen!2sus" ></iframe>
            <input type='text' className='email' placeholder='Your Email' />
            <input type="text" className='name' placeholder='Prefered Name' />
            <input type="text" className='subject' placeholder='Subject' />
            <textarea className='message' placeholder='Message'></textarea>
            <div className="submit"><input type="submit"  value='Send Message'/></div>
        </form>
    </div>
}