import "./VerticalCard1.css"

export default function VerticalCard1(props) {
    return (
    <div className="VerticalCard1 card">
        <div className="container">
            <img src={props.src} alt={props.alt} />
            <div className="text">
                {props.children}
            </div>
        </div>
    </div>
    )
}