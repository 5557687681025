// Used for home slide 4
import SectionTitle from "../text/SectionTitle"

export default function TitledCardList(props) {
    return (
    <div className="TitledCardList slide">
        <SectionTitle>{props.title}</SectionTitle>
        {props.children}
    </div>
    )
}