import "./HorizontalCard1.css"

export default function HorizontalCard1(props) {
    return (
    <div className="HorizontalCard1 card">
        <img src={props.src} alt={props.alt} />
        <div className="container">
            <h3>{props.title}</h3>
            <p>{props.text}</p>
        </div>
    </div>
    )
}