import TitleSlide from "../slides/TitleSlide"
import ArticleSection from "../text/ArticleSection"
import SectionTitle from "../text/SectionTitle"
import TextSection from "../text/TextSection"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

export default function Wind(props) {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Using Wind Energy</TitleSlide>
    <TextSection>
        <SectionTitle>Helping the Environment</SectionTitle>
        <ArticleSection>
            &emsp;Small-scale wind turbines can be installed on properties to generate electricity from wind energy. This is particularly beneficial in areas with consistent wind patterns. For homeowners, wind power offers a clean and sustainable energy source that can supplement or even replace grid electricity, reducing reliance on fossil fuels and lowering energy costs.
            <br />
            &emsp;Installing a small wind turbine on your property can transform the way you power your home. Wind power is a renewable energy source that harnesses the natural breeze to generate electricity. For homeowners, this means a significant reduction in electricity bills and a step towards energy independence. Wind turbines are especially beneficial in rural or open areas with consistent wind patterns, where they can generate substantial amounts of clean energy. By investing in wind power, you not only lower your carbon footprint but also contribute to a sustainable future.
        </ArticleSection>
    </TextSection>
    </>
}