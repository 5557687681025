import NavButton from "../buttons/NavButton";
import NavLogo from "../buttons/NavLogo";
import { DropdownButton, DropdownOption } from "../buttons/DropdownButton";
import Hamburger from "../buttons/Hamburger";
import './Navbar.css'
import { useState } from "react";

export default function Navbar() {
    const [ navVisible, setNavVisible ] = useState(false)

    return (
    <div className="Navbar">
        <NavLogo />
        <Hamburger onClick={() => { setNavVisible(!navVisible) }} />
        <div className={`navLinks${navVisible?" visible":""}`}>
            <NavButton to="benefits" name="Benefits" onClick={() => { setNavVisible(!navVisible) }} />
            <NavButton to="initiatives" name="Initiatives" onClick={() => { setNavVisible(!navVisible) }} />
            <DropdownButton to="sources" name="Sources" onClick={() => { setNavVisible(!navVisible) }} >
                <DropdownOption to="/sources/solar" name="Solar" onClick={() => { setNavVisible(!navVisible) }} />
                <DropdownOption to="/sources/geothermal" name="Geothermal" onClick={() => { setNavVisible(!navVisible) }} />
                <DropdownOption to="/sources/wind" name="Wind" onClick={() => { setNavVisible(!navVisible) }} />
                <DropdownOption to="/sources/nuclear" name="Nuclear" onClick={() => { setNavVisible(!navVisible) }} />
                <DropdownOption to="/sources/hydropower" name="Hydropower" onClick={() => { setNavVisible(!navVisible) }} />
                <DropdownOption to="/sources/fossil-fuels" name="Fossil Fuels" onClick={() => { setNavVisible(!navVisible) }} />
            </DropdownButton>
            <NavButton to="news" name="News" onClick={() => { setNavVisible(!navVisible) }} />
            <NavButton to="about" name="About Us" onClick={() => { setNavVisible(!navVisible) }} />
            <NavButton to="documentation" name="TSA Info" onClick={() => { setNavVisible(!navVisible) }} />
        </div>
    </div>
    )
}