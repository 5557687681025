import './CardPalette2.css';
import SectionTitle from '../text/SectionTitle';

export default function CardPalette2(props) {
    return (
    <div className="CardPalette2 slide">
        <SectionTitle>{props.title}</SectionTitle>
        <div className="container">
            <div className="child card1">{props.card1}</div>
            <div className="child card2">{props.card2}</div>
            <div className="child card3">{props.card3}</div>
            <div className="child card4">{props.card4}</div>
        </div>
    </div>
    )
}