import LinkButton1 from "../buttons/LinkButton1"
import "./SourceCard.css"

export default function SourceCard(props) {
    console.log(props.src)
    return <div className="SourceCard slide" style={{backgroundImage:`url(${props.src})`}}>
        <div className="text">
            <h2 className="observable">{props.children}</h2>
            <p className="observable">{props.desc}</p>
            <div><LinkButton1 to={props.to}>See More</LinkButton1></div>
        </div>
    </div>
}