import TitleSlide from "../slides/TitleSlide"
import ArticleSection from "../text/ArticleSection"
import SectionTitle from "../text/SectionTitle"
import TextSection from "../text/TextSection"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

export default function Fossil(props) {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Fossil Fuels</TitleSlide>
    <TextSection>
        <ArticleSection>
            &emsp;Fossil fuels, such as coal, oil, and natural gas, are the primary culprits behind global warming and environmental degradation. Their extraction and combustion release significant amounts of carbon dioxide and other pollutants, leading to air and water pollution, habitat destruction, and adverse health effects. For homeowners and society at large, continuing to rely on fossil fuels is unsustainable and detrimental, emphasizing the urgent need to transition to cleaner, renewable energy sources.
        </ArticleSection>
    </TextSection>
    </>
}