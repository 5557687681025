import './AttributionTable.css'
import SectionTitle from '../text/SectionTitle'

import Image1 from '../images/shafagh-faridifar-6yPiMRZzFH0-unsplash.jpg'
import Image2 from '../images/vasilios-muselimis-C9NtZAEP-RI-unsplash-small.jpg'
import Image3 from '../images/khamkeo-vilaysing-y7-cY2ZTwHc-unsplash-small.jpg'
import Image4 from '../images/andreas-gucklhorn-Ilpf2eUPpUE-unsplash-small.jpg'
import Image5 from '../images/r-architecture-TRCJ-87Yoh0-unsplash.jpg'
import Image6 from '../images/francesco-gallarotti-ruQHpukrN7c-unsplash.jpg'
import Image7 from '../images/mischa-frank-6M9OhcblUHM-unsplash.jpg'
import Image8 from '../images/lukas-lehotsky-vMQFh9rAkeU-unsplash-small.jpg'
import Image9 from '../images/american-public-power-association-FUeb2npsblQ-unsplash-small.jpg'
import Image10 from '../images/sandie-clarke-q13Zq1Jufks-unsplash.jpg'
import Image11 from '../images/chris-leboutillier-TUJud0AWAPI-unsplash-small.jpg'
import Image12 from '../images/campaign-creators-gMsnXqILjp4-unsplash.jpg'
import Image13 from '../images/larm-rmah-r15ypiGxn6c-unsplash.jpg'
import Image14 from '../images/bill-mead-wmaP3Tl80ww-unsplash.jpg'
import Image15 from '../images/sean-d-YO5q_7BN7kQ-unsplash.jpg'
import Image16 from '../images/nathan-fertig-U8zsjmKA840-unsplash.jpg'
import Image17 from '../images/mike-kotsch-HNx4QLRgy2k-unsplash.jpg'

export default function AttributionTable() {
    return (
        <div className="AttributionTable slide">
        <SectionTitle>Image Attribution</SectionTitle>
        <table>
            <tr>
                <th>Image</th>
                <th>Credit</th>
                <th>Link</th>
            </tr>
            <tr>
                <td><img src={Image1} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@shafagh58?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Shafagh Faridifar</a> on <a href="https://unsplash.com/photos/green-grass-field-near-green-trees-and-mountains-during-daytime-6yPiMRZzFH0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
            </td>
                <td><a href="https://unsplash.com/photos/green-grass-field-near-green-trees-and-mountains-during-daytime-6yPiMRZzFH0">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image2} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@vasiliosm?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Vasilios Muselimis</a> on <a href="https://unsplash.com/photos/tilt-shift-photography-of-white-windmill-during-daytime-C9NtZAEP-RI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/tilt-shift-photography-of-white-windmill-during-daytime-C9NtZAEP-RI">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image3} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@mahkeo?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Khamkéo Vilaysing</a> on <a href="https://unsplash.com/photos/body-of-water-in-between-of-stone-y7-cY2ZTwHc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/body-of-water-in-between-of-stone-y7-cY2ZTwHc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image4} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@draufsicht?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Andreas Gücklhorn</a> on <a href="https://unsplash.com/photos/aerial-photography-of-grass-field-with-blue-solar-panels-Ilpf2eUPpUE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/aerial-photography-of-grass-field-with-blue-solar-panels-Ilpf2eUPpUE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image5} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@rarchitecture_melbourne?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">R ARCHITECTURE</a> on <a href="https://unsplash.com/photos/brown-wooden-seat-beside-white-wooden-table-TRCJ-87Yoh0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/brown-wooden-seat-beside-white-wooden-table-TRCJ-87Yoh0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image6} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@gallarotti?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Francesco Gallarotti</a> on <a href="https://unsplash.com/photos/green-plants-on-soil-ruQHpukrN7c?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/green-plants-on-soil-ruQHpukrN7c?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Left</a></td>
            </tr>
            <tr>
                <td><img src={Image7} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@halfcat?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Mischa Frank</a> on <a href="https://unsplash.com/photos/white-and-black-wooden-house-under-white-sky-during-daytime-6M9OhcblUHM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/white-and-black-wooden-house-under-white-sky-during-daytime-6M9OhcblUHM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image8} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@llehotsky?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Lukáš Lehotský</a> on <a href="https://unsplash.com/photos/gray-concrete-towers-under-white-clouds-and-blue-sky-during-daytime-vMQFh9rAkeU?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/gray-concrete-towers-under-white-clouds-and-blue-sky-during-daytime-vMQFh9rAkeU?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image9} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@publicpowerorg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">American Public Power Association</a> on <a href="https://unsplash.com/photos/water-dam-under-white-and-blue-skies-FUeb2npsblQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/water-dam-under-white-and-blue-skies-FUeb2npsblQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image10} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@honeypoppet?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Sandie Clarke</a> on <a href="https://unsplash.com/photos/person-holding-brown-and-black-frog-q13Zq1Jufks?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/person-holding-brown-and-black-frog-q13Zq1Jufks?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image11} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@chrisleboutillier?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Chris LeBoutillier</a> on <a href="https://unsplash.com/photos/white-and-black-ship-on-sea-under-white-clouds-TUJud0AWAPI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/white-and-black-ship-on-sea-under-white-clouds-TUJud0AWAPI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image12} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@campaign_creators?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Campaign Creators</a> on <a href="https://unsplash.com/photos/man-standing-in-front-of-people-sitting-beside-table-with-laptop-computers-gMsnXqILjp4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/man-standing-in-front-of-people-sitting-beside-table-with-laptop-computers-gMsnXqILjp4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image13} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@larm?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Larm Rmah</a> on <a href="https://unsplash.com/photos/shallow-focus-photography-of-person-holding-yellow-flower-plant-r15ypiGxn6c?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/shallow-focus-photography-of-person-holding-yellow-flower-plant-r15ypiGxn6c?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image14} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@wmmead?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Bill Mead</a> on <a href="https://unsplash.com/photos/man-in-white-dress-shirt-and-blue-denim-jeans-sitting-on-white-and-black-solar-panel-wmaP3Tl80ww?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/man-in-white-dress-shirt-and-blue-denim-jeans-sitting-on-white-and-black-solar-panel-wmaP3Tl80ww?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image15} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@paus_d_?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Sean D</a> on <a href="https://unsplash.com/photos/graphical-user-interface-YO5q_7BN7kQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/graphical-user-interface-YO5q_7BN7kQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image16} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@nathanfertig?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Nathan Fertig</a> on <a href="https://unsplash.com/photos/white-teapot-and-tow-flower-vases-on-windowpane-U8zsjmKA840?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/white-teapot-and-tow-flower-vases-on-windowpane-U8zsjmKA840?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
            <tr>
                <td><img src={Image17} alt="" /></td>
                <td>Photo by <a href="https://unsplash.com/@mike?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Mike Kotsch</a> on <a href="https://unsplash.com/photos/green-plant-HNx4QLRgy2k?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></td>
                <td><a href="https://unsplash.com/photos/green-plant-HNx4QLRgy2k?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Link</a></td>
            </tr>
        </table>
        </div>
    )
}