import TitleSlide from "../slides/TitleSlide"
import ArticleSection from "../text/ArticleSection"
import SectionTitle from "../text/SectionTitle"
import TextSection from "../text/TextSection"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

export default function Solar(props) {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Using Solar Energy</TitleSlide>
    <TextSection>
        <SectionTitle>Helping the Environment</SectionTitle>
        <ArticleSection>
            &emsp;Solar power harnesses energy from the sun through photovoltaic panels installed on rooftops. For homeowners, this means access to a renewable and virtually limitless energy source that can significantly reduce electricity bills and environmental impact. Advances in solar technology have made panels more efficient and affordable, making it easier for individuals to adopt solar energy and even sell excess power back to the grid.
            <br />
            &emsp;Solar power systems are an excellent investment for homeowners looking to cut energy costs and promote environmental sustainability. By installing photovoltaic panels on your roof, you can convert sunlight into electricity to power your home. Modern solar panels are more efficient and affordable than ever, providing significant savings on utility bills. Additionally, many regions offer incentives and rebates for solar installations, making it an even more attractive option. With a solar power system, you can produce clean energy, reduce your reliance on the grid, and even sell excess power back to the utility company.
        </ArticleSection>
    </TextSection>
    </>
}