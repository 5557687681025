import SourceCards from "../slides/SourceCards"
import SourceCard from "../cards/SourceCard"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

import solarImg from "../images/andreas-gucklhorn-Ilpf2eUPpUE-unsplash.jpg"
import geoImg from "../images/khamkeo-vilaysing-y7-cY2ZTwHc-unsplash.jpg"
import windImg from "../images/vasilios-muselimis-C9NtZAEP-RI-unsplash.jpg"
import nuclearImg from "../images/lukas-lehotsky-vMQFh9rAkeU-unsplash.jpg"
import hydroImg from "../images/american-public-power-association-FUeb2npsblQ-unsplash.jpg"
import fossilImg from "../images/chris-leboutillier-TUJud0AWAPI-unsplash.jpg"

export default function Sources(props) {
    useEffect(()=>observe(), [])
    return <SourceCards>
        <SourceCard src={solarImg} to="/sources/solar" desc="Solar power converts sunlight into electricity through photovoltaic panels installed on rooftops.">Solar Energy</SourceCard>
        <SourceCard src={geoImg} to="/sources/geo" desc="Geothermal energy systems use the earth's stable temperatures to heat and cool homes efficiently.">Geothermal Energy</SourceCard>
        <SourceCard src={windImg} to="/sources/wind" desc="Wind power uses wind turbines to generate electricity from the natural breeze.">Wind Energy</SourceCard>
        <SourceCard src={nuclearImg} to="/sources/nuclear" desc="Nuclear energy generates electricity with minimal greenhouse gas emissions and is a highly efficient low-carbon energy source.">Nuclear Energy</SourceCard>
        <SourceCard src={hydroImg} to="/sources/hydropower" desc="Hydropower uses the energy of flowing water to generate clean and reliable electricity.">Hydropower</SourceCard>
        <SourceCard src={fossilImg} to="/sources/fossil-fuels" desc="Fossil fuels are a primary cause of climate change today.">Fossil Fuels</SourceCard>
    </SourceCards>
}
