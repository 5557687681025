import { Outlet } from "react-router-dom";
import Navbar from "../navigation/Navbar";
import Footer from "../slides/Footer";

export default function Layout() {
    return (
    <>
    <Navbar />
    <div className="content">
        <Outlet />
    </div>
    <Footer />
    </>)
}