import TitleSlide from "../slides/TitleSlide"
import ArticleSection from "../text/ArticleSection"
import SectionTitle from "../text/SectionTitle"
import TextSection from "../text/TextSection"
import observe from "../hooks/intersectionObserver";
import { useEffect } from "react";

export default function Nuclear(props) {
    useEffect(()=>observe(), [])
    return <>
    <TitleSlide>Using Nuclear Energy</TitleSlide>
    <TextSection>
        <SectionTitle>Helping the Environment</SectionTitle>
        <ArticleSection>
            &emsp;Nuclear energy is a powerful and efficient source of clean electricity that significantly reduces greenhouse gas emissions. While not typically a direct option for homeowners, supporting nuclear energy on a larger scale helps ensure a stable and reliable supply of clean power, contributing to the overall reduction of fossil fuel dependence and promoting environmental sustainability.
            <br />
            &emsp;Supporting nuclear energy is a crucial step towards a sustainable and reliable energy future. Nuclear power plants generate a significant amount of electricity with minimal greenhouse gas emissions, making them a key player in combating climate change. While nuclear energy may not be an option for home installations, homeowners can advocate for and support policies that promote safe and efficient nuclear power. By endorsing nuclear energy, you contribute to a diversified energy grid that reduces dependence on fossil fuels and helps stabilize energy prices.
        </ArticleSection>
    </TextSection>
    </>
}