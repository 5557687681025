import TitleSlide from "../slides/TitleSlide";
import AnnotatedImage from "../slides/AnnotatedImage";
import SectionTitle from "../text/SectionTitle";
import ArticleSection from "../text/ArticleSection";
import Testimonial from "../slides/Testimonial";
import TextSection from "../text/TextSection";
import observe from "../hooks/intersectionObserver";
import GrassImage from "../images/larm-rmah-r15ypiGxn6c-unsplash.jpg"
import { useEffect } from "react";

import Placeholder from "../images/nuts.jpg";

export default function Benefits() {
    useEffect(()=>observe(), [])
    return <>
        <TitleSlide>Benefits of Green Energy</TitleSlide>
        <TextSection>
            <AnnotatedImage src={GrassImage}></AnnotatedImage>
            <SectionTitle>Your Impact</SectionTitle>
            <ArticleSection>
                &emsp;According to the EPA, annual CO2 emissions from electricity consumption are 14,020 pounds per household. In the United States, the electric power sector makes up 31% of the nation's total carbon emissions. This energy consumption rate is simply unsustainable.
                <br />
                &emsp;By switching to green energy systems in your home, you can save money and contribute to bettering our planet. Green energy systems significantly reduce your carbon footprint, and contribute to the fight against climate change. This transition not only helps the environment but also often leads to financial savings over time, as renewable energy sources like solar and energy efficient appliances can lower or even eliminate utility bills. Moreover, adopting green energy increases energy independence, reducing reliance on non-renewable resources and susceptibility to energy price fluctuations. Additionally, it can boost property values and influence others in your community to make similar environmentally conscious choices. Switching to green energy is a forward-thinking decision that benefits both the planet and your personal well-being.
            </ArticleSection>
        </TextSection>
        {/*
        <TextSection>
            <Testimonial title="A Heartfelt Story" src={Placeholder} alt="Brad's Headshot" citation="Brad">“I remember the day my dog died and girlfriend left me for Chad. But then I went green and got a billion dollars and started dating Chad.”</Testimonial>
            <Testimonial title="A Heartfelt Story" src={Placeholder} alt="Brad's Headshot" citation="Brad">“I remember the day my dog died and girlfriend left me for Chad. But then I went green and got a billion dollars and started dating Chad.”</Testimonial>
            <Testimonial title="A Heartfelt Story" src={Placeholder} alt="Brad's Headshot" citation="Brad">“I remember the day my dog died and girlfriend left me for Chad. But then I went green and got a billion dollars and started dating Chad.”</Testimonial>
        </TextSection>
        */}
    </>
}