import { useEffect } from "react"
import AttributionTable from "../slides/AttributionTable"
import DocumentEmbed from "../slides/DocumentEmbed"
import CopyrightChecklist from "../misc/copyright-checklist.pdf"
import observe from "../hooks/intersectionObserver"
import RawWorklog from "../misc/raw-worklog.pdf"
import TextSection from "../text/TextSection"
import SectionTitle from "../text/SectionTitle"
import Worklog from "../misc/final-worklog.pdf"

export default function Documentation() {
    useEffect(()=>observe(), [])
    return (
    <>
    <DocumentEmbed title="Copyright Checklist" src={CopyrightChecklist} />
    <DocumentEmbed title="Official Work Log" src={Worklog} />
    <DocumentEmbed title="Raw Work Log" src={RawWorklog} />
    <TextSection>
    <SectionTitle>Technologies Used</SectionTitle>
    <ul>
        <li>Kinsta for static site hosting</li>
        <li>Coded in ReactJS with create-react-app</li>
        <li>Vanilla CSS for styling; no CSS frameworks or component libraries used</li>
        <li>ImageMagick for image compression and optimization</li>
        <li>Site design in Figma</li>
        <li>Planning work with Google Drive</li>
    </ul>
    </TextSection>
    <TextSection>
        <SectionTitle>Sources Used</SectionTitle>
        <ul>
            <li>Stock images and video clips from Pexels and Unsplash used</li>
            <li><a href="https://www.energy.gov/energysaver/small-wind-electric-systems">https://www.energy.gov/energysaver/small-wind-electric-systems</a></li>
            <li><a href="https://www.energy.gov/energysaver/fall-and-winter-energy-saving-tips">https://www.energy.gov/energysaver/fall-and-winter-energy-saving-tips</a></li>
            <li><a href="https://education.nationalgeographic.org/resource/putting-wind-work/">https://education.nationalgeographic.org/resource/putting-wind-work/</a></li>
            <li><a href="https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy">https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy </a></li>
            <li><a href="https://www.energy.gov/energysaver/benefits-residential-solar-electricity">https://www.energy.gov/energysaver/benefits-residential-solar-electricity</a></li>
            <li><a href="https://education.nationalgeographic.org/resource/geothermal-energy/">https://education.nationalgeographic.org/resource/geothermal-energy/</a></li>
            <li><a href="https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy">https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy</a></li>
            <li><a href="https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy">https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy</a></li>
            <li><a href="https://www.energy.gov/ne/articles/advantages-and-challenges-nuclear-energy">https://www.energy.gov/ne/articles/advantages-and-challenges-nuclear-energy</a></li>
            <li><a href="https://www.irs.gov/credits-deductions/energy-efficient-home-improvement-credit">https://www.irs.gov/credits-deductions/energy-efficient-home-improvement-credit</a></li>
            <li><a href="https://www.epa.gov/ghgemissions/assumptions-and-references-household-carbon-footprint-calculator">https://www.epa.gov/ghgemissions/assumptions-and-references-household-carbon-footprint-calculator</a></li>
            <li><a href="https://www.cbo.gov/system/files/2022-12/58419-co2-emissions-elec-power.pdf">https://www.cbo.gov/system/files/2022-12/58419-co2-emissions-elec-power.pdf</a></li>
        </ul>
    </TextSection>
    <AttributionTable />
    </>
    )
}