import InitiativeCard from "../cards/InitiativeCard"
import InitiativesSection from "../slides/InitiativesSection"

var selected = new Set()

function select(tag, elem) {
    if(selected.has(tag)) {
        selected.delete(tag)
        elem.target.classList.remove("enabled")
    } else {
        selected.add(tag)
        elem.target.classList.add("enabled")
    }
    document.querySelectorAll(".InitiativeCard").forEach(e=>{
        if(selected.size === 0 || [...selected].some(c=>e.classList.contains(c))) {
            e.classList.remove("disabled")
        } else {
            e.classList.add("disabled")
        }
    })
}

export default function Initiatives(props) {
    return <>
    <InitiativesSection select={select} tags={[
        "EVs", "Solar", "Wind", "Appliances"
    ]}>
        <InitiativeCard 
            title="30% of Cost as Tax credit"
            tags={["Solar", "Wind"]} 
            desc="Installing solar panels or a wind turnine will give you 30% of their cost as federal tax credits."
            href="https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
        />
        <InitiativeCard
            title="$2,000 Tax Credit"
            tags={["Appliances"]}
            desc="$2,000 tax credit for purchasing efficient heating and cooling equipment like a heat pump or central heating unit."
            href="https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
        />
        <InitiativeCard
            title="$150 tax Credit"
            tags={[]}
            desc="$150 tax credit for a home energy audit conducted by an inspector."
            href="https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
        />
        <InitiativeCard 
            title="$4,000 rebate"
            tags={["Appliances"]}
            desc="Potential $4,000 rebate for electric appliances and home retrofits, for low income households that increases to up to $8,000."
            href="https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
        />
        <InitiativeCard 
            title="Covering the Cost"
            tags={["Appliances"]}
            desc="Low and moderate income households can access rebates that cover up to 100% of the cost of installing electric appliances like a heat pump, water heater, or clothes dryer."
            href="https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
        />
        <InitiativeCard 
            title="$7,500 Tax Credit"
            tags={["EVs"]}
            desc="If buying an electric vehicle you can receive a tax credit of $7,500 and if you own an electric vehicle already you can get a tax credit of $4,000"
            href="https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
        />
    </InitiativesSection>
    </>
}