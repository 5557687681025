function effect(elements) {
    elements.forEach(e => {
        if (e.isIntersecting) {
            e.target.classList.add("visible")
        }
    });
}

const observer = new IntersectionObserver(effect, {
    threshold : .25,
})

export default function observe() {
    Array.from(document.getElementsByClassName("observable")).forEach(element=>{
        if (!element.classList.contains("observed")){
            element.innerHTML = `<obs>${element.innerHTML}</obs>`
            element.classList.add("observed")
            observer.observe(element)
        }
    })
}