import "./ImageCard1.css"
import { Link } from "react-router-dom"

export default function ImageCard1(props) {
    return (
    <div className="ImageCard1 card">
        <Link className="container" to={props.href} style={{ backgroundImage: `url(${props.backgroundImage})` }}>
            <div className="text">
                {props.children}
            </div>
        </Link>
    </div>
    )
}